@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

/* .community {
  background-image: url("/solidbg.png");
} */

body {
  font-family: "Inter", sans-serif;
}

.jost {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;

}
