
  .progress {
    
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 0;
  }
  
  /* .progress svg {
    transform: translateX(-100px) rotate(-90deg);
  } */
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 5%;
    fill: none;
  }
  
  .bg {
    stroke: #7C9AB9;
    opacity: 0.2;
  }
  
  .progress .indicator {
    stroke: #7C9AB9;
  }
  

  
